var site = site || {};
var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);

    if ($statusList.length < 1) {
      return null;
    }

    $statusList.data('sku-base-id', skuBaseId);
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product:init', '.js-product', function (e) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  Drupal.behaviors.inventoryStatusV1 = {
    update: function ($statusList, skuData, $product) {
      var skuBaseId = $statusList.data('sku-base-id');
      var status = skuData.INVENTORY_STATUS;
      var params = {};
      var displayNotifyMe = Drupal.settings.common && Drupal.settings.common.enable_toos_cs_notify;
      var displaySoldOut = Drupal.settings.common && Drupal.settings.common.enable_sold_out;

      params.skuBaseId = skuBaseId;
      params.isShoppable = skuData.isShoppable;
      params.status = status;
      // 2 --> temp OOS
      // 3 --> coming soon
      // 7 --> sold out
      if (!skuData || !status) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      $('li', $statusList).hide();

      var $addButtons = $product.find('.js-add-to-cart');
      // for some reason button text updates are tied to status list updates
      var statusUpdated = false;

      $addButtons.each(function (i, obj) {
        var $addBtn = $(obj);
        var $addBtnCopy = $addBtn.find('.js-add-to-cart__copy');
        var $stickyCart = $('.js-sticky__cart__cta__link');

        $addBtn.removeClass('button--disabled').data('disabled', false);

        if ($addBtnCopy.length) {
          $addBtnCopy.text($addBtn.data('default-label'));
        } else {
          $addBtn.text($addBtn.data('default-label'));
        }

        // pass data to sticky add to bag button
        params.label = $addBtn.filter(':visible').data('default-label');
        if ($stickyCart.length) {
          site.stickyButton.update(params);
        }
        var $statusToShow = $('.js-inv-status-' + status, $statusList);

        if ($statusToShow.length > 0) {
          var $btnLabels = $addBtn.data('inv-status-labels');

          if (!_.isUndefined($btnLabels)) {
            if (!!$btnLabels && !!$btnLabels[status]) {
              if ($addBtnCopy.length) {
                $addBtnCopy.text($btnLabels[status]);
              } else {
                $addBtn.text($btnLabels[status]);
              }
              params.label = $btnLabels[status];
            }

            if (!skuData.isShoppable) {
              $addBtn.addClass('button--disabled').data('disabled', true);
            }
          }

          if (
            (displayNotifyMe && (skuData.INVENTORY_STATUS == 2 || skuData.INVENTORY_STATUS == 3)) ||
            (displaySoldOut && skuData.INVENTORY_STATUS == 7)
          ) {
            $statusToShow.show();
          }
          // previous code assumed one button. so status update triggered
          // only once. keep it that way since I'm not 100% sure what the
          // change would be.
          if (statusUpdated) {
            return;
          }

          // Button must be visible be used for sticky
          if (!$addBtn.is(':visible')) {
            return;
          }

          if ($statusToShow.data('display')) {
            $statusToShow.show();
          }
          $statusList.trigger('inv_status_display:updated');

          // pass data to sticky add to bag button
          if ($stickyCart.length) {
            site.stickyButton.update(params);
          }
          statusUpdated = true;
        }
      });
      if (displayNotifyMe && (skuData.INVENTORY_STATUS == 2 || skuData.INVENTORY_STATUS == 3)) {
        $(document).trigger('product.waitlist.init', skuData);
      } else if (displaySoldOut && skuData.INVENTORY_STATUS == 7) {
        $(document).trigger('product.waitlist.reset', skuData);
      }
    }
  };
})(jQuery);
